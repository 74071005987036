<script setup lang="ts">
import { handleCopy } from '~/utils/copy'
import { useDayjs } from '#dayjs'
import icon_movie from '~/assets/image/purchase_history/icon_movie.png'
import icon_movie_ticket from '~/assets/image/purchase_history/icon_movie_ticket.png'
import icon_member_card from '~/assets/image/purchase_history/icon_member_card.png'

withDefaults(
  defineProps<{
    item: PurchaseRecord
  }>(),
  {}
)
const buyImgs = new Map([
  [1, icon_member_card], // 1-会员卡
  [2, icon_movie_ticket], // 2-观影劵
  [3, icon_movie] // 3-影片 JAVBuyType枚举
])
const dayjs = useDayjs()
function getType(changeType?: number, num?: number): string {
  let type = ''
  if (changeType === 10) {
    type = `${num} 张`
  } else {
    type = `${num} 金币`
  }
  return type
}
</script>

<template>
  <div pos-relative>
    <div mb-4 mt-4 flex flex-row pb-2 class="bord">
      <div class="h-14.75 w-14.75 flex items-center justify-center rounded-50% p-2.5 bg-242943" mr-2>
        <img w-full :src="buyImgs.get(item?.buyType || 1)" />
      </div>
      <div w-76>
        <div flex justify-between>
          <span text-sm ct-e8e8e8>{{ item.buyTypeName }}</span>
          <span text-sm ct-e8e8e8>{{ item.buyType === 2 ? `${item.skuName}张` : item.skuName }}</span>
        </div>
        <div my-2 flex items-center justify-between>
          <span flex items-center text-11px ct-e8e8e8
            >{{ item?.eventId ?? '-' }}&nbsp;&nbsp;
            <span w-10 border-b-bluegray rounded text-center text-sm bg-414660 ct-cfcfcf @click="handleCopy(item.eventId)"
              >复制</span
            >
          </span>
        </div>
        <div flex items-center justify-between>
          <span text-xs ct-5c5e69>{{ dayjs(item.createdAt).format('YYYY/MM/DD HH:mm') }}</span>
          <span text-xs ct-e8e8e8>-{{ getType(item?.changeType, (item.changeType === 10 ? item.skuNumber : item.realAmount)) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
