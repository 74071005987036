<script setup lang="ts">
import empty_file from '~/assets/image/empty/empty_file@2x.png'
import { PersonalApi } from '~/net/apis/personal_api'
import { handleCopy } from '~/utils/copy'

const onClickLeft = () => history.back()
const actions = [
  { name: '全部类型', value: 0 },
  { name: '会员卡', value: 1 },
  { name: '观影券', value: 2 },
  { name: '影片', value: 3 }
]
const show = ref(false)
const type = ref('全部类型')
const buyType = ref(0)
const timeCate = ref(0)

function onSelect(item: any) {
  // 默认情况下点击选项时不会自动收起
  // 可以通过 close-on-click-action 属性开启自动收起
  show.value = false
  type.value = item.name
  buyType.value = item.value
}
const actionsDate = [
  { name: '最近1周', value: 0 },
  { name: '最近1月', value: 1 },
  { name: '最近3月', value: 2 },
  { name: '最近半年', value: 3 }
]
const showDate = ref(false)
const date = ref('最近1月')
function onSelect1(item: any) {
  // 默认情况下点击选项时不会自动收起
  // 可以通过 close-on-click-action 属性开启自动收起
  showDate.value = false
  date.value = item.name
  timeCate.value = item.value
}
// const list = ref<any[]>([])
const list = [
  {
    img: '/customer.png',
    time: '2022/01/22 22:06',
    title: '异世界之风云雄霸AV界',
    type: '影片',
    id: '293425031152154',
    money: '-200.00'
  },
  {
    img: '/customer.png',
    time: '2022/01/22 22:06',
    title: '异世界之风云雄霸AV界',
    type: '影片',
    id: '293425031152154',
    money: '-200.00'
  },
  {
    img: '/customer.png',
    time: '2022/01/22 22:06',
    title: '异世界之风云雄霸AV界',
    type: '影片',
    id: '293425031152154',
    money: '-200.00'
  }
]

async function getPayList() {
  const data = await PersonalApi.purchaseRecord({
    pageNum: 1,
    pageSize: 50,
    buyType: 0,
    timeCate: 0
  })
  console.log(data)
}

function getType(changeType?: number, num?: number): string {
  let type = ''
  if (changeType === 10) {
    type = `${num} 张`
  } else {
    type = `${num} 金币`
  }
  return type
}
// getPayList()
</script>

<template>
  <div h-screen ct-d0d0d0>
    <van-nav-bar title="购买记录" left-arrow @click-left="onClickLeft" />
    <div class="bord">
      <div h-10 flex flex-row>
        <div flex flex-1 items-center justify-center @click="show = true">
          {{ type }}
          <van-icon :name="show ? 'arrow-up' : 'arrow-down'" ml-2 />
        </div>
        <div flex flex-1 items-center justify-center @click="showDate = true">
          {{ date }}
          <van-icon :name="showDate ? 'arrow-up' : 'arrow-down'" ml-2 />
        </div>
      </div>
    </div>
    <BasePullRefreshList
      wrap-class="w-full grid grid-cols-1 gap2 px-15px h-87vh"
      :request-api="PersonalApi.purchaseRecord"
      :offset-list="20"
      :request-params="{
        pageNum: 1,
        pageSize: 16,
        orderStatus: null,
        buyType,
        timeCate
      }"
    >
      <template #="{ data }">
        <PurchaseRecord :item="data as PurchaseRecord" />
      </template>
    </BasePullRefreshList>
    <van-action-sheet
      v-model:show="show"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
      @cancel="show = false"
      @select="onSelect"
    />
    <van-action-sheet
      v-model:show="showDate"
      :actions="actionsDate"
      cancel-text="取消"
      close-on-click-action
      @cancel="showDate = false"
      @select="onSelect1"
    />
  </div>
</template>

<style scoped>
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
.bord {
  border-bottom: 1px solid #41466040;
}
:deep(.van-action-sheet__item) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__cancel) {
  background: #242943;
  color: #e8e8e8;
}
:deep(.van-action-sheet__gap) {
  background: #414660;
}
:deep(.van-action-sheet__item:hover) {
  color: #fd5f03;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
</style>
